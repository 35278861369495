import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalValuesService } from '../../global-values/global-values.service';
import { MasterListRequest } from '../../../models/template/many-to-one-relation/many-to-one-relation';
import { SessionStorageService } from 'angular-web-storage';
@Injectable({
  providedIn: 'root',
})
/** Service to get all ManyToOneRelation details  */
export class ManyToOneRelationService {
  /**Varaible which holds value of mt1Subject */
  private mt1Subject = new Subject<any>();
  /**
   * Constructor which holds httpclient and service
   * @param http HTTPClient imports
   * @param {GlobalValuesService}
   */
  constructor(
    private http: HttpClient,
    public globalValue: GlobalValuesService,
    public sessionStorageService: SessionStorageService
  ) {}

  /**Function to handle setmt1ItemInfo */
  setmt1ItemInfo(val: any): void {
    this.mt1Subject.next(val);
  }
  /**Function to handle getmt1ItemInfo */
  getmt1ItemInfo(): Observable<any> {
    return this.mt1Subject.asObservable();
  }
  /**Variable which holds mt1SearchDataUrl */
  mt1SearchDataUrl: string = this.globalValue.creatorBaseURL + 'master/list';
  /**Function to handle getManyToOneSearchData
   * @param params
  */
  getManyToOneSearchData(params : any,pimIdNotRequired ?:Boolean): Observable<MasterListRequest> {
    let userDetails = this.sessionStorageService.get('userDetails');
    if(userDetails.workflowConfigurations.length>0 && userDetails.workflowConfigurations[0][params.className] && !pimIdNotRequired){
      let t = userDetails.workflowConfigurations[0][params.className].join('|')
      if(t != ''){

      if(params.search == undefined)
      params.search = '{"AND":{},"OR":{"pim_id":"'+t+'"}}'
      else{
      let x= params.search.indexOf("OR")
      if(params.search[x+5]!='}'){
        params.search = params.search.slice(0, x+5) + '"pim_id":"'+t+'",'+ params.search.slice(x+5)
      }
      else{
        params.search = params.search.slice(0, x+5) + '"pim_id":"'+t+'"'+ params.search.slice(x+5)
      }
      }
    }
    }
    
    return this.http.get<MasterListRequest>(this.mt1SearchDataUrl, {
      params: params
    });
  }


  mt1SearchDataContentUrl: string = this.globalValue.creatorBaseURL + 'form/content/list';
  /**Function to handle getManyToOneSearchData
   * @param params
  */
  getManyToOneSearchDataContent(params : any): Observable<any> {
    let sessionData = this.sessionStorageService.get('userToken');
    var headers = new HttpHeaders({ 
      token: sessionData.token
    }); 
    return this.http.get<any>(this.mt1SearchDataContentUrl, {
      params: params,
      headers: headers
    });
  }

/**Variable which holds mt1 Search Detail Url */
  mt1SearchDetailUrl: string = this.globalValue.creatorBaseURL + 'master/detail?masterId=';
/**function to get Many To One SearchDetail */
  getManyToOneSearchDetail(id : any): Observable<MasterListRequest> {
    return this.http.get<MasterListRequest>(this.mt1SearchDetailUrl+id);
  }
  
}
