/** Class which holds DocParserRequest */
export class DocParserRequest {
}
/** Interface which holds DocParserRequest */
export interface DocParserRequest {
    /** Varaible which holds the contentType data */
    templateTypes: string[];
    /** Varaible which holds the country data */
    country: string;
    /** Varaible which holds the crop data */
    crop: string;
    /** Varaible which holds the cropstage data */
    cropstage: string[];
    /** Varaible which holds the contentType data */
    contentType: string[];
}