import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
/**Component which holds GlobalValuesService functionality */

@Injectable({
  providedIn: 'root'
})

export class GlobalValuesService {
  /**Variable which holds creatorBaseURL */
  creatorBaseURL : string = '';
  /**Variable which holds httpOptions */
  httpOptions: Object =[] ;
  /**Constructor lifecycle hook */
  constructor() {
    // if(environment.production){
    //   this.creatorBaseURL = environment.baseUrl
    // }
    // else{
      this.creatorBaseURL = environment.baseUrl
    // }
    // console.log("BaseURL", this.creatorBaseURL)
   }
}
