/**class which holds ContentSaveResponse functionality */
export class curatorText {
     /** Variables which holds copyright */
    static copyright = "Copyright"
     /** Variables which holds bayer */
    static bayer = "Bayer AG"
     /** Variables which holds bayerTag */
    static bayerTag = "Science for a better life"
     /** Variables which holds logout */
    static logout = "Logout"
     /** Variables which holds support */
    static support = "Support"
     /** Variables which holds error */
    static success = "Success"
     /** Variables which holds copyright */
    static error = "Error"
     /** Variables which holds welcome */
    static welcome = "Welcome"
     /** Variables which holds utilSelection */
    static utilSelection = "Utility Selection"
     /** Variables which holds utilQues */
    static utilQues = "What type of utility would you like to select?"
     /** Variables which holds utilType */
    static utilType = "Select Utility Type"
     /** Variables which holds next */
    static next = "Next"
     /** Variables which holds addFiles */
    static addFiles = "Add Files Below"
     /** Variables which holds upload */
    static upload =  "Upload"
     /** Variables which holds process */
    static process = "Process"
     /** Variables which holds summary */
    static summary = "Summary"
     /** Variables which holds view */
    static view = "View"
     /** Variables which holds download */
    static download = "Download"
     /** Variables which holds reUpload */
    static reUpload = "Re-Upload"
     /** Variables which holds delete */
    static delete = "Delete"
     /** Variables which holds fileName */
    static fileName = "File Name"
     /** Variables which holds of */
    static of = "of"
     /** Variables which holds actions */
    static actions = "Actions"
     /** Variables which holds contentType */
    static contentType = "Select Content Type"
     /** Variables which holds attrName */
    static attrName = "Attribute Name"
     /** Variables which holds type */
    static type = "Type"
     /** Variables which holds add */
    static add = "Add"
     /** Variables which holds save */
    static save = "Save"
     /** Variables which holds back */
    static back = "Back"
     /** Variables which holds edit */
    static edit = "Edit"
    /** Variables which holds contType */
    static contType = "Content Type"
    /** Variables which holds Template Type */
    static tempType = "Template Type"
    /** Variables which holds Template Id */
    static tempId = "Template Id"
     /** Variables which holds addURL */
    static addURL = "Add Website URL below"
     /** Variables which holds launch */
    static launch = "Launch"
     /** Variables which holds locator */
    static locator = "Locator"
     /** Variables which holds webURL */
    static webURL = "Website URL"
     /** Variables which holds noData */
    static noData = "No Data Found"
     /** Variables which holds title */
    static title = "Title"
     /** Variables which holds titleAndUrl */
    static titleAndUrl = "Enter Title and URL"
     /** Variables which holds editurl */
    static editurl = "Edit URL"
     /** Variables which holds url */
    static url = "URL"
     /** Variables which holds country */
    static country = "Country"
     /** Variables which holds crop */
    static crop = "Crop"
     /** Variables which holds stage */
    static stage = "Stage"
     /** Variables which holds errorpopup */
    static errorpopup = "Error Popup"
     /** Variables which holds notabletoparse */
    static notabletoparse = "Not able to parse"
     /** Variables which holds contenttype404 */
    static contenttype404 = "Not matching content types:"
     /** Variables which holds attrtype404 */
    static attrtype404 = "Not matching attribute names:"
     /** Variables which holds fixreupload */
    static fixreupload = "**Please fix these errors and upload again for parsing"
     /** Variables which holds value */
    static value ="value"
     /** Variables which holds search */
    static search =  "search"
     /** Variables which holds deloutline */
    static deloutline =  "delete_outline"
     /** Variables which holds LavanyaDS */
    static LavanyaDS =  "Lavanya DS"
     /** Variables which holds noattriavailable */
    static noattriavailable = "No Attributes available"
     /** Variables which holds direct */
    static direct =  "Direct"
     /** Variables which holds close */
    static close = "close"
     /** Variables which holds cropcontentType */
    static cropcontentType = "Crop & Content Type"
     /** Variables which holds benchmark */
    static benchmark = "Benchmark"
     /** Variables which addoredit copyright */
    static addoredit ="Add/Edit"
     /** Variables which invalid startDate */
     static invalidStartDate ="Invalid start date"
      /** Variables which addoredit copyright */
    static invalidEndDate ="Invalid end date"
     /** Variables which addoredit copyright */
    static cancel ="Cancel"
     /** Variables which No Results Found */
     static results404 ="No Results Found"
    /** Variables which holds Creator Name*/
    static creatorName ="Creator Name"

}
