  export class creatorText {
  /**Variable that holds the static bayerTag text */
  static bayerTag = 'Science for a better life';
  /**Variable that holds the static logout text */
  static logoutText = 'Logout';
  /**Variable that holds the static support text */
  static supportText = 'Support';
  /**Variable that holds the static searchResults text */
  static searchResultsText = 'Search Results';
  /**Variable that holds the static advFilters text */
  static filtersText = 'Filters';
  /**Variable that holds the static creationDate text */
  static creationDateText = 'Creation Date';
  /**Variable that holds the static search text */
  static searchTextButton = 'Search';
  /**Variable that holds the static reset text */
  static resetText = 'Reset';
  /**Variable that holds the static noResultFound text */
  static noResultFoundText = 'No results found';
  /**Variable that holds the static roleSwitcher text */
  static roleSwitcherText = 'Role Switcher';
  /**Variable that holds the static instructions text */
  static roleSelection = 'Select from the roles listed below:';
  /**Variable that holds the static proceed text */
  static proceedText = 'Proceed';
  /**Variable that holds the static cancel text */
  static cancelText = 'Cancel';
  /**Variable that holds the static delete text */
  static deleteFormText = 'Delete form';
  /**Variable that holds the static deleteConfirmation text */
  static deleteConfirmation = 'Do you want to delete the form?';
  /**Variable that holds the static success text */
  static successText = 'Success';
  /**Variable that holds the static successMessage text */
  static successMessage = 'Form has been submitted successfully!';
  /**Variable that holds the static validation text */
  static validationFailedText = 'Validation Failed';
  /**Variable that holds the static errorMessage text */
  static errorMessage = 'Please fill the dependent field(s) first.';
  /**Variable that holds the static createContent text */
  static createContentText = 'Create Content';
  /**Variable that holds the static openNewTab text */
  static openNewTabText = 'Open in new tab';
  /**Variable that holds the static bayerAg text */
  static bayerAgText = 'Bayer AG';
  /**Variable that holds the static menu text */
  static menuText = 'menu';
  /**Variable that holds the static switchRole text */
  static switchRoleText = 'Switch Role';
  /**Variable that holds the static english text */
  static englishText = 'English';
  /**Variable that holds the static item2 text */
  static item2Text = 'Item 2';
  /**Variable that holds the static contentType text */
  static contentTypeText = 'Content Type';
  /**Variable that holds the static advanced text */
  static advancedText = 'Advanced'
  /**Variable that holds the static open text */
  static openText = 'Open';
  /**Variable that holds the static copy text */
  static copyText = 'Copy';
  /**Variable that holds the static paste text */
  static pasteText = 'Paste';
  /**Variable that holds the static rename text */
  static renameText = 'Rename';
  /**Variable that holds the static refresh text */
  static refreshText = 'Refresh';
  /**Variable that holds the static dashboard text */
  static dashboardText = 'Dashboard';
  // static dashboardCreateContentText= "CREATE CONTENT"
  /**Variable that holds the static selection text */
  static contentSelection =
    'What type and category of content would you like to select?';
  /**Variable that holds the static selectType text */
  static selectContentTypeText = 'Select Content Type';
  /**Variable that holds the static warningType text */
  static PleaseSelectContentTypeText = 'Please select content type!';
  /**Variable that holds the static selectCategory text */
  static selectContentCategoryText = 'Select Content Category';
  /**Variable that holds the static warningCategory text */
  static PleaseSelectContentCategoryText = 'Please select content category!';
  /**Variable that holds the static next text */
  static nextText = 'NEXT';
  /**Variable that holds the static welcome text */
  static welcomeText = 'Welcome';
  /**Variable that holds the static dashboardSummary text */
  static dashboardSummaryText = 'Dashboard Summary';
  /**Variable that holds the static Translation Summary text */
  static translationSummaryText = 'Translation Summary'
  /**Variable that holds the static filter text */
  static filterText = 'Filter';
  /**Variable that holds the static apply text */
  static applyText = 'Apply';
  /**Variable that holds the static gridOptions text */
  static gridOptionsText = 'Grid Options';
  /**Variable that holds the static selectgridColumns text */
  static selectgridColumnsText = 'Select grid columns';
  /**Variable that holds the static Delete all text */
  static deleteAllText = 'Delete All';
  /**Variable that holds the static export text */
  static exportText = 'Export';
  /**Variable that holds the static formFillup text */
  static formFillup = 'Fill the form and add media!';
  /**Variable that holds the static title text */
  static titleText = 'Title';
  /**Variable that holds the static heading text */
  static categoryHeading = 'In_En_Ri_Kh_Blast';
  /**Variable that holds the static inProgress text */
  static inProgressText = 'In Progress';
  /**Variable that holds the static save text */
  static saveText = 'Save';
  /**Variable that holds the static folder text */
  static foldersText = 'Folders';
  /**Variable that holds the static sampleImage text */
  static sampleImageText = 'Sample Image';
  /**Variable that holds the static information text */
  static informationText = 'Information';
  /**Variable that holds the static adjust text */
  static adjustText = 'Adjust brightness and contrast';
  /**Variable that holds the static resize text */
  static resizeText = 'Resize';
  /**Variable that holds the static size text */
  static defaultSize = 'Default (500 x 500)';
  /**Variable that holds the static setSize text */
  static setSizeText = 'Set size';
  /**Variable that holds the static width text */
  static widthText = 'W';
  /**Variable that holds the static height text */
  static heightText = 'H';
  /**Variable that holds the static imagePreview text */
  static imagePreviewText = 'Image Preview';
  /**Variable that holds the static process text */
  static processText = 'PROCESS';
  /**Variable that holds the static add text */
  static addText = 'ADD';
  /**Variable that holds the static back text */
  static backText = 'Back';
  /**Variable that holds the static login text */
  static loginText = 'Login';
  /**Variable that holds the static multimedia text */
  static multimediaText = 'Multimedia';
  /**Variable that holds the static agrow text */
  static agrowText = 'Agrow';
  /**Variable that holds the static smart text */
  static smartText = 'Smart';
  /**Variable that holds the static agrowCaption text */
  static agrowCaption = 'One Stop Agro Solutions';
  /**Variable that holds the static userNameValidation text */
  static userNameValidation = 'Username is incorrect';
  /**Variable that holds the static userNameTouched text */
  static userNameTouched = 'Username is required!';
  /**Variable that holds the static passwordValidation text */
  static passwordValidation = 'Password is required';
  /**Variable that holds the static passwordCharacterCheck text */
  static passwordCharacterCheck = 'Password is incorrect';
  /**Variable that holds the static externalLogin text */
  static externalLoginButton = 'LOGIN';
  /**Variable that holds the static internalLogin text */
  static internalLoginButton = 'LOGIN WITH CWID';
  /**Variable that holds the static copyRight text */
  static copyRightText = 'Copyright';
  /**Variable that holds the static bayerContent text */
  static bayerContentText = 'Bayer AG. All rights reserved.';
  /**Variable that holds the static available text */
  static availableText = 'Available:';
  /**Variable that holds the static addBySearch text */
  static addBySearchText = 'ADD BY SEARCH';
  /**Variable that holds the static generateKeywords text */
  static generateKeywordsText = 'GENERATE KEYWORDS';
  /**Variable that holds the static dashboardDetails text */
  static dashboardDetails = [
    {
      role: 'Dashboard-Creator',
      data: {
        column: [
          { name: 'className', title: 'Content Category' },
          { name: 'ContentType', title: 'Content Type' },
          { name: 'key', title:'Content Name'},
          { name: 'creationDate', title: 'Creation Date' },
          { name: 'WorkflowStatus', title: 'Status' },
          { name: 'Crop_MT1_Value', title: 'Crop Name' },
          { name: 'TotalDays', title: 'Total no. of days' },
          { name: 'expiryDate', title: 'Review Date' }, 
         
          // { name: 'CreatorName', title: 'Name' },
          // { name: 'BayerLanguage_MT1', title: 'Language' },
          { name: 'DaysWithCreator', title: 'Days with Creator' },
          { name: 'DaysWithReviewer', title: 'Days with Content Reviewer' },
          { name: 'DaysWithStatutoryReviewer', title: 'Days with Statutory Reviewer' },
          { name: 'DaysWithApprover', title: 'Days with Approver' },
          { name: 'Country_MT1_Value', title: 'Country' },
          { name: 'state', title: 'State' },
          { name:'id', title: 'Content Id' }
        ],
        grid: {
          className: true,
          ContentType:true,
          key:true,
          creationDate: true,
          WorkflowStatus: true,
          Crop_MT1_Value: true,
          expiryDate: false,
          CreatorName: false,
          TotalDays: false,
          DaysWithCreator: false,
          DaysWithReviewer: false,
          DaysWithStatutoryReviewer: false,
          DaysWithApprover: false,
          Country_MT1_Value: false,
          state: false,
          // BayerLanguage_MT1: false,
          id: false
        },
        filter: {
          contentType: '',
          contentCategory: '',
          Crop_MT1: '',
          Country_MT1: '',
          state: '',
        },
        statusCount: [
          { status: 'In Progress', value: 0, active: false },
          { status: 'Revisit Content', value: 0, active: false },

          // { status: 'Reopen', value: 0, active: false },
          // { status: 'Submit for Review', value: 0, active: false },
          // { status: 'Statutory Review', value: 0, active: false },
          { status: 'Content Review', value: 0, active: false },
          { status: 'Statutory Review', value: 0, active: false },
          // { status: 'Approve and Publish', value: 0, active: false },
          { status: 'Approval', value: 0, active: false },
          { status: 'Published', value: 0, active: false },
          // { status: 'Withhold Approved', value: 0, active: false },
        ],
      },
    },
    {
      role: 'Translation-Creator',
      data: {
        column: [
          { name: 'className', title: 'Content Category' },
          { name: 'ContentType', title: 'Content Type' },
          { name: 'key', title:'Content Name'},
          { name: 'TranslationLanguage', title: 'Language' },
          { name: 'TotalDays', title: 'Total no. of days' },
          { name: 'creationDate', title: 'Creation Date' },
          { name: 'expiryDate', title: 'Review Date' },
          { name:'TranslationStatus', title: 'Translation Status'},
          { name: 'Country_MT1_Value', title: 'Country' },
          { name: 'wordCount', title: 'Word Count' },
          { name: 'pageCount', title: 'Page Count' },
          { name: 'UniversityExpertName', title: 'University Expert Name'},
          { name: 'TranslationCompletionDate', title: 'Translation Completion Date'}
        ],
        grid: {
          className: true,
          contentCategory: true,
          key:true,
          TotalDays: true,
          ContentType: true,
          creationDate: true,
          expiryDate: true,
          TranslationLanguage: true,
          TranslationStatus: true,
          Country_MT1_Value : false,
          wordCount: true,
          pageCount: true,
          UniversityExpertName: true,
          TranslationCompletionDate: true
        },
        filter: {
          contentType: '',
          contentCategory: '',
          Crop_MT1: '',
          Country_MT1: '',
          state: '',
        },
        statusCount: [
          { status: 'Translation Ready', value: 0, active: false },
          { status: 'In Translation', value: 0, active: false },
          { status: 'Translation Comp', value: 0, active: false },
        ],
      },
    },
    {
      role: 'Translator',
      data: {
        column: [
          { name: 'className', title: 'Content Category' },
          { name: 'key', title:'Content Name'},
          { name: 'Crop_MT1_Value', title: 'Crop' },
          { name: 'id', title: 'Content Id' },
          { name: 'TranslationLanguage', title: 'Language' },
          { name: 'TranslationStatus', title: 'Status' },
          { name: 'wordCount', title: 'Word Count' },
          { name: 'pageCount', title: 'Page Count' },
          { name: 'TranslationCompletionDate', title: 'Translation Completion Date'}
        ],
        grid: {
          TranslationStatus: true,
          className: true,
          id: true,
          TranslationLanguage: true,
          receivedDate: true,
          Crop_MT1_Value:false,
          key:true,
          wordCount:true,
          pageCount:true,
          TranslationCompletionDate:true
        },
        filter: {
          contentType: '',
          contentCategory: '',
          cropName: '',
          country: '',
          state: '',
        },
        statusCount: [
          { status: 'Review in Progress', value: 0, active: false },
          { status: 'Revisit Content', value: 0, active: false },
          { status: 'In Translation', value: 0, active: false },
          { status: 'Completed', value: 0, active: false },
        ],
      },
    },
    {
      role: 'Content Reviewer',
      data: {
        column: [
          { name: 'className', title: 'Content Category' },
          { name: 'ContentType', title: 'Content Type' },
          { name: 'key', title:'Content Name'},
          { name: 'Crop_MT1_Value', title: 'Crop Name' },
          { name: 'TotalDays', title: 'Total no. of days' },
          { name: 'creationDate', title: 'Creation Date' },
          { name: 'expiryDate', title: 'Review Date' },
          { name: 'CreatorName', title: 'Name' },
          { name: 'WorkflowStatus', title: 'Status' },
          { name: 'DaysWithCreator', title: 'Days with Creator' },
          { name: 'DaysWithReviewer', title: 'Days with Content Reviewer' },
          { name: 'DaysWithStatutoryReviewer', title: 'Days with Statutory Reviewer' },
          { name: 'DaysWithApprover', title: 'Days with Approver' },
          { name: 'Country_MT1_Value', title: 'Country' },
          { name: 'state', title: 'State' },
        ],
        grid: {
          WorkflowStatus: true,
          className: true,
          ContentType: true,
          key:true,
          Crop_MT1_Value: false,
          creationDate: true,
          expiryDate: true,
          CreatorName: true,
          TotalDays: true,
          DaysWithCreator: false,
          DaysWithReviewer: false,
          DaysWithStatutoryReviewer: false,
          DaysWithApprover: false,
          Country_MT1_Value: true,
          state: true,
        },
        filter: {
          contentType: '',
          contentCategory: '',
          cropName: '',
          country: '',
          state: '',
        },
        statusCount: [
          { status: 'New Content', value: 0, active: false },
          { status: 'Revist Content', value: 0, active: false },
          { status: 'Content Review', value: 0, active: false },
          { status: 'Statutory Review', value: 0, active: false },
          { status: 'Approval', value: 0, active: false },
          { status: 'Published', value: 0, active: false },
        ],
      },
    },
  ];
    /**Variable that holds the static recycleBinDetails text */
    static recycleBinDetails = [ 
      { 
        data: {
          column: [
            { name: 'className', title: 'Content Name' },
            { name:'key',title:'Key'},
            { name: 'id', title: 'File Id' },
            { name: 'path', title: 'Path' },
            { name: 'creationDate', title: 'Created Date' }, 
            { name: 'modificationDate', title: 'Deleted Date'}
          ],
          grid: {
            id: true,
            creationDate: true,
            modificationDate: true,
            path: true,
            key:true,
            className:true,            
          },
          filter: {
            id: '',
            contentCategory: '',
            path: '', 
            creationDate: '',
            modificationDate:'',
            key:'',
          },
          statusCount: [
            { status: 'Review in Progress', value: 0, active: false },
            { status: 'Revisted Content', value: 0, active: false },
            { status: 'Content Review', value: 0, active: false },
            { status: 'Statutory Review', value: 0, active: false },
            { status: 'Approval', value: 0, active: false },
            { status: 'Published', value: 0, active: false },
          ],
        },
      },
    ];
  /**Variable that holds the static type text */
  static categoryText = 'Category';
  /**Variable that holds the static IWD text */
  static IWDText = 'IWD';
  /**Variable that holds the static agronomy text */
  static agronomyText = 'Agronomy';
  /**Variable that holds the static prdoucts text */
  static prdouctsText = 'Prdoucts';
  /**Variable that holds the static country text */
  static countryText = 'Country';
  /**Variable that holds the static India text */
  static IndiaText = 'India';
  /**Variable that holds the static Pakistan text */
  static PakistanText = 'Pakistan';
  /**Variable that holds the static language text */
  static languageText = 'Language';
  /**Variable that holds the static advancedSearchOpen text */
  static advancedSearchOpenText = 'Click here for advance search';
  /**Variable that holds the static status text */
  static statusText = 'Status';
  /**Variable that holds the static content category text */
  static contentCategoryText = 'Content Category';
  /**Variable that holds the static state text */
  static stateText = 'State';
  /**Variable that holds the static crop text */
  static cropText = 'Crop';
  /**Variable that holds the static stages text */
  static stageText = 'Stages';
  /**Variable that holds the Document Praser text */
  static documentPraserText = 'Document Praser';
  /**Variable that holds the Spell Checker text */
  static spellCheckerText = 'Spell Checker';
  /**Variable that holds the Web Scrapper text */
  static webScrapperText = 'Web Scrapper';
  /**Variable that holds the keyword ToolTip */
  static keywordToolTipText = 'Please seprate the Keywords using commas and Save the form before using Genrated Keywords.';
  /*Variable that holds the static text initiate translation */
  static initiateTranslationText='Initiate Translation';
  /*Variable that holds the static text Translation Languages */
  static translationLanguages='Translation Languages';
  /*Variable that holds the default message for multimedia */
  static multimediaDefaultMsg = 'Please select any folder to proceed.';
  /*Variable that holds static call button text */
  static editLinkText = 'Edit Link';
  /*Variable that holds static cropWiseContent text */
  static cropWiseContentText = 'Crop Wise Content';
  /*Variable that holds static contentWiseSummary text */
  static contentWiseSummaryText = 'Content Wise Summary';
  /*Variable that holds static cropWiseContentTimelines text */
  static cropWiseContentTimelinesText = 'Crop Wise Content Timelines';
  /*Variable that holds static completed text */
  static completedText = 'Completed';
  /*Variable that holds static delayed text */
  static delayedText = 'Delayed';
  /*Variable that holds static notStarted text */
  static notStartedText = 'Not Started';
  /*Variable that holds static noDataError text */
  static noDataErrorText = 'No data available for selected values'
  /*Variable that holds static contentWiseSummaryandStatus text */
  static contentWiseSummaryandStatusText = 'Content Wise Summary and Status';
  /*Variable that holds the error message for No data found */
  static noDataFoundText = 'No data found.';
  /*Variable that holds static cropName text */
  static cropNameText = 'Crop Name';
  /*Variable that holds static editText text */
  static editText = 'Edit';
  /*Variable that holds static save and publish text */
  static savePublishText = 'Save & Publish';
  /*Variable that holds static translateContent text */
  static translateContentText = 'Translate content';
  /*Variable that holds static originalContent text */
  static originalContentText = 'Original Content';
  /*Variable that holds static warning text */
  static warningText = 'Warning';
  /*Variable that holds static selectValuesCaution text */
  static selectValuesCautionText = 'Please select values to be scrapped from';
  /*Variable that holds static cropStage text */
  static cropStageText = 'CropStage';
  /*Variable that holds static formFillCaution text */
  static formFillCaution = 'Please fill the above required fields in the form';
  /*Variable that holds static process text */
  static process = 'Process';
  /*Variable that holds static noContentsFound text */
  static noContentsFoundText = 'No Contents Found!';
  /*Variable that holds static processing text */
  static processingText = 'Processing';
  /*Variable that holds static sendToReviewer text */
  static sendToReviewerText = 'Send to Reviewer';
  /**Variable that holds comment box text */
  static commentBoxText = 'Add Comment'
  /*Variable that holds static notes text */
  static notesText = 'Notes';
  /*Variable that holds static text value*/
  static text = 'Text:';
  /*Variable that holds static path text */
  static pathText = 'Path:';
  /*Variable that holds static title text */
  static title = 'Title:';
  /*Variable that holds static contentTracker text */
  static contentTrackerText = 'Content Tracker';
  /*Variable that holds static recycleBin text */
  static recycleBinText = 'Recycle Bin';
  /*Variable that holds static createVariant text */
  static createVariantText = 'Create Variant';
  /**Variable that holds the static Generate text*/
  static generateText = 'Generate';
  /**Variable that holds the static email error message text*/
  static emailErrorMsg = 'Please enter a valid Email Address';
  /**Variable that holds the static Browse text*/
  static browseText = 'Browse';
  /**Variable that holds the static browser error message text*/
  static browserErrorMsg = 'Your browser does not support the video tag.';
  /**Variable that holds the static phone number error message text*/
  static phoneNumberErrorMsg = 'Please enter a valid Phone Number';
  /**Variable that holds the static country text with colon */
  static countryWithColon = 'Country:';
  /**Variable that holds the static Crop text with colon */
  static cropwithColon = 'Crop:';
  /**Variable that holds the static content type text with colon */
  static contentTypeWithColon = 'Content Type:';
  /**Variable that holds the static creator name text */
  static creatorName = 'Creator Name';
  /**Variable that holds the static name text */
  static Name = 'Name';
  /**Variable that holds submit text */
  static submit = 'Submit'
  /**Variable that holds logout warning heading */
  static logoutWarningHeadingText = "Logout Warning";
}
