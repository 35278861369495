import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
/**Component which holds SwitchroleService functionality */
@Injectable({
  providedIn: 'root',
})
export class SwitchroleService {
  /**Variable which holds userRoleSubject */
  private userRoleSubject = new Subject<any>();
  /**Constructor lifecycle hook */
  constructor() {}
  /**Function which handles setUserRole
   * @param setUserRole
   */
  setUserRole(val: any): void {
    this.userRoleSubject.next(val);
  }
  /**Function that handles getUserRole */
  getUserRole(): Observable<any> {
    return this.userRoleSubject.asObservable();
  }
}
