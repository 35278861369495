import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalValuesService } from '../global-values/global-values.service';
import { DashboardResponse } from '../../models/dashboard/dashboard.response';
import { Observable, Subject } from 'rxjs';
import { SessionStorageService } from 'angular-web-storage';
import { DashboardRequest } from '../../models/dashboard/dashboard.request';
import { DocParserRequest } from '../../models/utilities/docParser.request';
import { DocParserResponse } from '../../models/utilities/docParser.response';
import { WebScrapperResponse } from '../../models/utilities/webscrapper.response';
import { WebScrapperRequest } from '../../models/utilities/webScrapper.request';

@Injectable({
  providedIn: 'root',
})
/** Service to get all dashboard details  */
export class UitiltyService {
  /**Creates an instance of documenter
   * @param {HttpClient}
   * @param {GlobalValuesService}
   * @param {SessionStorageService}
   */

  /**
   * Constructor which holds httpclient
   * @param http HTTPClient imports
   */
  constructor(
    public http: HttpClient,
    public globalValue: GlobalValuesService,
    public sessionStorageService: SessionStorageService
  ) {}

  /**Variable which holds internalLoginUrl */
  docParserUrl: string =
    this.globalValue.creatorBaseURL + 'get/contentbyoptions';

  /**Varaible which holds value of webScrapperSubject */
  private webScrapperSubject = new Subject<any>();
  /** Function to get all dashboard details
   * @return response that hold dashboard related values
   */
  getDocParserDetails(
    docParserParam: DocParserRequest
  ): Observable<DocParserResponse> {
    let sessionData = this.sessionStorageService.get('userToken');
    var headers = new HttpHeaders({
      token: sessionData.token,
    });
    return this.http.post<DocParserResponse>(
      this.docParserUrl,
      docParserParam,
      { headers }
    );
  }

  /**Variable which holds internalLoginUrl */
  webScrapperUrl: string = this.globalValue.creatorBaseURL + 'scrap/content';
  /** Function to get all dashboard details
   * @return response that hold dashboard related values
   */
  getWebScrapperDetails(
    webScrapperParam: WebScrapperRequest
  ): Observable<WebScrapperResponse> {
    let sessionData = this.sessionStorageService.get('userToken');
    var headers = new HttpHeaders({
      token: sessionData.token,
    });
    return this.http.post<WebScrapperResponse>(
      this.webScrapperUrl,
      webScrapperParam,
      { headers }
    );
  } 
  // webScrapperUrl: string = '/assets/json/ws-02.json';

  // getWebScrapperDetails(
  //   webScrapperParam: WebScrapperRequest
  // ){
  //   return this.http.get(this.webScrapperUrl);
  // }

  /**Function to handle setWebScrapperInfo */
  setWebScrapperInfo(val: any): void {
    this.webScrapperSubject.next(val);
  }
  /**Function to handle getWebScrapperInfo */
  getWebScrapperInfo(): Observable<any> {
    return this.webScrapperSubject.asObservable();
  }

  /**Variable which holds webScrapper Value */
  webScrapper:any = []
  /**Function to handle Get webScrapperData */
  get webScrapperData(): any{
    return this.webScrapper;
  }
  /**Function to handle Set webScrapperData */
  set webScrapperData(val: any){
    this.webScrapper = val;
  }
}
